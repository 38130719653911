.metric-name {
    font-size: 20px;
    font-weight: 500;
    color: black;
}

.metric-score {
    /* float: left;
   */
    width: 100%;
    float: left;
    font-size: 28px;
    font-weight: 800;

}

.metric-stat {
    float: left;
    font-size: 15px;
}


#dashboard-metrics {
    padding: 16px 16px;
    display: flex;
    flex-direction: column;
    text-align: left;
    min-height: 136px;
}

#dashboard-metrics .metric-score {
    /* font-size: 44px;
    font-weight: 600;
    line-height: 44px; */
    width: 100%;
    float: left;

}

/*Chat Bot*/



.ABB_CommonUX_Dialog__root.dialog-container-chatbot>.ABB_CommonUX_Dialog__content {
    padding: 0 !important;
}

.chat-window .overflow-hidden {
    background-color: red !important;
}

.chat-window {
    width: 770px;
    height: 520px;
}

.chatbot-head-icon {
    width: 70px;
    height: 70px;
    margin: 8px 16px 8px 24px;

}

.chatbot-head {
    width: 100%;
    background-color: #efefef;
    text-align: left;
    padding: 8px;
    font-size: medium;
    font-weight: 500;
}

.chatbot-title {
    font-size: 24px;
    vertical-align: middle;
}

.dialog-container-chatbot.ABB_CommonUX_Dialog__root>.ABB_CommonUX_Dialog__header>.ABB_CommonUX_Dialog__closeButton {
    margin: 16px;
    position: absolute;
    right: 5px;
    top: 8px;
}

.dialog-container-chatbot {
    width: 802px !important;
    height: 600px;
    position: absolute !important;

    top: 28% !important;
    left: 56% !important;
    display: flex !important;
    flex-direction: column !important;
    /* background-color: #3498db; */
    z-index: 1100 !important;
}

.dialog-container-chatbot .ABB_CommonUX_Dialog__closeButton {
    display: block !important;

}

.c-bot-tooltip-content {
    width: 240px !important;
    left: 1662px !important;
    font-size: medium;
    font-weight: 500;
}


#chatbot-icon {
    position: fixed;
    display: flex;
    justify-content: end;
    bottom: 12px;

    right: 26px;
    font-family: inherit;
    z-index: 1000;
    /* Ensure the icon appears above other content */
}

#chatbot-icon-link {
    display: block;
    width: 100px;
    /* Adjust size as needed */
    height: 100px;
    /* Adjust size as needed */
    background-color: #007bff;
    /* Change color as desired */
    border-radius: 50%;
    /* Create a circular shape */
    text-decoration: none;
    overflow: hidden;
    /* Ensure the image stays within the circular shape */
}

#chatbot-icon-link img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* Maintain aspect ratio and cover the entire area */
}


/*Chat Bot*/

/* 
.metric-red {
    color: #EB5757;
}

.metric-green {
    color: #27AE60;
} */

.metric-red-arrow {
    color: #EB5757;
}

.metric-green-arrow {
    color: #196F3D;
}

.dashboard-page-content {
    max-height: 100vh;
    min-height: 100vh;
    overflow: scroll;
    padding: 32px 16px;
    background: #EFEFEF
}

.metric-nochange {
    color: black;
    padding-top: 5px !important
}

.widgets-container {
    display: flex;
    flex-direction: column;

}

.metric-desc {

    text-transform: lowercase;
    /* color: #27AE60; */
    font-weight: 500;

}

/* Extra Large screens 100 % zoom*/

@media (max-width: 1920px) {


    /* CSS styles for extra large screens */
    .metric-stat-dash {
        float: left;
        font-size: 17px;
    }
}

/* Extra Large screens 125 % zoom*/

@media only screen and (max-width: 1536px) {
    .metric-name {
        font-size: 17px;
        font-weight: 500;
        color: black;
    }

    .metric-stat-dash {
        float: left;
        font-size: 14px;
    }

    #dashboard-metrics {
        padding: 16px 16px;
        display: flex;
        flex-direction: column;
        text-align: left;
        min-height: 112px;
        height: 115px;
    }
}

/* Extra Large screens 150 % zoom*/

@media (max-width: 1440px) {
    #dashboard-metrics {
        padding: 16px 16px;
        display: flex;
        flex-direction: column;
        text-align: left;
        min-height: 112px;
    }

    #dashboard-metrics {
        padding: 16px 16px;
        display: flex;
        flex-direction: column;
        text-align: left;
        /* min-height: 136px; */
        height: 115px;
    }

    .metric-score {

        width: 100%;
        float: left;
        font-size: 25px;
        font-weight: 800;
    }

    .metric-name {
        font-size: 12px;
        font-weight: 500;
        color: black;
    }

    .metric-stat-dash {
        float: left;
        font-size: 13px;
    }

}